
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































.magazine-grid {
  display: grid;
  grid-gap: 3rem;
  list-style-type: none;

  @include mq(m) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(l) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;

    &.col-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.magazine-grid__item {
  @include mq(l) {
    &.highlight {
      column-span: 1/3;
    }
  }
}
