
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































.project-content {
  margin-top: $spacing * 2;
  border-top: 1px solid $c-light-grey;

  @include mq(m) {
    margin-top: $spacing * 4;
    /* stylelint-disable declaration-no-important */
    padding-right: col(2, 12) !important;
    padding-left: col(2, 12) !important;
    /* stylelint-enable declaration-no-important */
  }
}

.project-content__wysiwyg-outer {
  position: relative;
  overflow: hidden;
  height: 55rem;

  &::after {
    @include get-all-space;

    content: '';
    display: block;
    background: linear-gradient(
      180deg,
      rgba($c-black, 0) 70%,
      rgba($c-white, 1) 100%
    );
    transition: opacity 0.2 ease-out;

    .is-open & {
      opacity: 0;
    }
  }
}

.project-content__btn-outer {
  @extend %text-center;

  margin-top: $spacing * 2;
}

.project-content__btn {
  ::v-deep {
    .icon {
      width: 12px;
      height: 7px;
      /* stylelint-disable-next-line declaration-no-important */
      transform: rotate(0) !important;

      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      .is-open & {
        /* stylelint-disable-next-line declaration-no-important */
        transform: rotate(180deg) !important;
      }
    }
  }
}
