
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































































































































.magazine-category__highlighted {
  margin-bottom: $spacing * 1.5;

  @include mq(l) {
    margin-bottom: $spacing * 2.5;
  }
}

.magazine-category__button {
  display: flex;
  justify-content: center;
}

.magazine-category__content {
  padding-bottom: 0;
}

.magazine-category__testimonials {
  @include mq($until: l) {
    .magazine-category__heading + & {
      --max-lines: 2;
    }
  }
}
